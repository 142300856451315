import { notification } from 'antd'
import apiClient from '../index'

export const getHolidayList = async (params) => {
  const { skip, limit, year } = params

  try {
    return await apiClient.get('holidays/getAllHolidays', {
      params: {
        skip,
        limit,
        year,
      },
    })
  } catch (error) {
    if (error) {
      notification.error({
        message: error?.message,
      })
    }
    return null
  }
}
// we can use it for holiday list in dashboard
export const getAllCurrentYearHolidays = async () => {
  try {
    return await apiClient.get('holidays/getAllCurrentYearHolidays')
  } catch (error) {
    if (error) {
      notification.error({
        message: error?.message,
      })
    }
    return null
  }
}

export const addHoliday = async (holidayData) => {
  try {
    return await apiClient.post('holidays', holidayData)
  } catch (error) {
    if (error?.response?.data?.statusCode) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.message,
      })
    }
    return null
  }
}

export const addHolidays = async (holidayData) => {
  try {
    return await apiClient.post('holidays/addHolidayManually', holidayData)
  } catch (error) {
    if (error?.response?.data?.statusCode) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.message,
      })
    }
    return null
  }
}

export const updateHoliday = async (id, holidayData) => {
  try {
    return await apiClient.put(`holidays/updateHoliday/${id}`, holidayData)
  } catch (error) {
    if (error?.response?.data?.statusCode) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.message,
      })
    }
    return null
  }
}

export const deleteHoliday = async (id) => {
  try {
    return await apiClient.delete(`holidays/deleteHoliday/${id}`)
  } catch (error) {
    if (error) {
      notification.error({
        message: error?.message,
      })
    }
    return null
  }
}
