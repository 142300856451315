import { actions } from './action'

// Initial State
const initialState = {
  employeeLeaveList: [],
  totalCount: 0,
  loading: false,
  error: null,
  year: new Date().getFullYear(),
  pagination: {
    skip: 0,
    limit: 10,
  },
  pageSize: 10,
  currentPage: 1,
}

// Reducer
const leaveReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_EMPLOYEE_LEAVE_LIST:
      return { ...state, loading: true }
    case actions.FETCH_EMPLOYEE_LEAVE_SUCCESS:
      return {
        ...state,
        loading: false,
        employeeLeaveList: action.payload.employeeLeaveList,
        totalCount: action.payload.totalCount,
      }
    case actions.FETCH_EMPLOYEE_LEAVE_FAILURE:
      return { ...state, loading: false, error: action.payload }
    case actions.HANDEL_YEAR:
      return { ...state, year: action.payload }
    case actions.LEAVE_HANDLE_PAGINATION:
      return { ...state, pagination: action.payload }
    default:
      return state
  }
}

export default leaveReportReducer
