import { getListOfEvents, getEventTable } from 'services/axios/events'
import { getMasterGlobalTypeList } from 'services/axios/config'

const actions = {
  FETCH_EVENT_DATA: 'FETCH_EVENT_DATA',
  DELETE_EVENT: 'DELETE_EVENT',
  FETCH_EVENT_LOADING: 'FETCH_EVENT_LOADING',
  GET_EVENT_STATUS: 'GET_EVENT_STATUS',
  FETCH_EVENT_LIST: 'FETCH_EVENT_LIST',
  EVENT_HANDLE_PAGINATION: 'HOLIDAY_HANDLE_PAGINATION',
  HANDEL_YEAR: 'HANDEL_YEAR',
}

// Fetch Events Data Action
export const fetchEventsData = () => {
  return async (dispatch) => {
    dispatch({ type: actions.FETCH_EVENT_LOADING })
    try {
      const response = await getListOfEvents()
      console.log('ressss>>>', response)
      dispatch({
        type: actions.FETCH_EVENT_LIST,
        payload: response?.data?.eventData,
      })
    } catch (error) {
      console.error('Error fetching events:', error)
    }
  }
}

// Fetch Events List with Pagination Action
export const getEventListAction = ({ skip, limit, year }) => {
  return async (dispatch) => {
    dispatch({ type: actions.FETCH_EVENT_LOADING })
    try {
      const response = await getEventTable({ skip, limit, year })
      dispatch({
        type: actions.FETCH_EVENT_LIST,
        payload: response?.data?.eventData,
      })
    } catch (error) {
      console.error('Error fetching event list:', error)
    }
  }
}

// Get Event Status Action
export const getEvent = () => {
  return async (dispatch) => {
    try {
      const response = await getMasterGlobalTypeList('event_status')
      if (response?.status === 200 && response?.data?.data?.length > 0) {
        dispatch({
          type: actions.GET_EVENT_STATUS,
          payload: response?.data?.data,
        })
      }
    } catch (error) {
      console.error('Error fetching event status:', error)
    }
  }
}

export const handleYear = (year) => {
  return {
    type: actions.HANDEL_YEAR,
    payload: year,
  }
}

export const handlePagination = (pagination) => {
  return {
    type: actions.EVENT_HANDLE_PAGINATION,
    payload: pagination,
  }
}

export default actions
