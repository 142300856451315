import { getHolidayList, getAllCurrentYearHolidays } from 'services/axios/holidays'

export const actions = {
  GET_HOLIDAY_LIST: 'GET_HOLIDAY_LIST',
  HOLIDAY_HANDLE_PAGINATION: 'HOLIDAY_HANDLE_PAGINATION',
  DELETE_HOLIDAY_LIST: 'DELETE_HOLIDAY_LIST',
  HANDEL_YEAR: 'HANDEL_YEAR',
}

export const getHolidayListAction = ({ skip, limit, year }) => {
  return async (dispatch) => {
    try {
      const response = await getHolidayList({ skip, limit, year })
      dispatch({
        type: actions.GET_HOLIDAY_LIST,
        payload: {
          holidayList: response?.data?.data?.holidays,
          totalCount: response?.data?.data?.totalCount,
        },
      })
    } catch (error) {
      console.error('Error fetching holiday list:', error)
    }
  }
}
// we can use it for holiday list in dashboard
export const getHolidayListAll = () => {
  return async (dispatch) => {
    try {
      const response = await getAllCurrentYearHolidays()
      dispatch({
        type: actions.GET_HOLIDAY_LIST,
        payload: {
          holidayList: response?.data?.data?.holidays,
          totalCount: response?.data?.data?.totalCount,
        },
      })
    } catch (error) {
      console.error('Error fetching holiday list:', error)
    }
  }
}

export const handleYear = (year) => {
  return {
    type: actions.HANDEL_YEAR,
    payload: year,
  }
}

export const handlePagination = (pagination) => {
  return {
    type: actions.HOLIDAY_HANDLE_PAGINATION,
    payload: pagination,
  }
}
