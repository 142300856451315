import { notification } from 'antd'
import apiClient from '../index'

// Fetch Leave Report API
export const getLeaveReport = async (params) => {
  const { skip, limit, year, searchTextValue } = params

  try {
    return await apiClient.get('leaveReport/getAllEmployees', {
      params: {
        skip,
        limit,
        year,
        searchTextValue,
      },
    })
  } catch (error) {
    if (error) {
      notification.error({
        message: error?.message,
      })
    }
    return null
  }
}
