import { actions } from './action'

const initialState = {
  holidayList: [],
  totalCount: 0,
  year: new Date().getFullYear(),
  pagination: {
    skip: 0,
    limit: 10,
  },
  pageSize: 10,
  currentPage: 1,
}

const holidayListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_HOLIDAY_LIST:
      return {
        ...state,
        holidayList: action.payload.holidayList,
        totalCount: action.payload.totalCount,
      }
    case actions.HOLIDAY_HANDLE_PAGINATION:
      return { ...state, pagination: action.payload }
    case actions.HANDEL_YEAR:
      return { ...state, year: action.payload }
    default:
      return state
  }
}

export default holidayListReducer
