import actions from './action'

const initialState = {
  data: [],
  totalCount: 0,
  year: new Date().getFullYear(),
  pagination: {
    skip: 0,
    limit: 10,
  },
  pageSize: 10,
  currentPage: 1,
  eventStatus: [],
}

const fetchEventsData = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_EVENT_LIST:
      return {
        ...state,
        data: action.payload,
        totalCount: action.payload?.totalCount,
      }

    case actions.EVENT_HANDLE_PAGINATION:
      return { ...state, pagination: action.payload }

    case actions.HANDEL_YEAR:
      return { ...state, year: action.payload }

    case actions.GET_EVENT_STATUS:
      return {
        ...state,
        eventStatus: action.payload,
      }

    default:
      return state
  }
}

export default fetchEventsData
