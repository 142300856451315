/* eslint-disable consistent-return */
import { notification } from 'antd'
import ApiEndPoints from 'utils'
import apiClient from '../index'

export const addNewEvent = async (eventData) => {
  try {
    return await apiClient.post(ApiEndPoints.EVENTS, eventData)
  } catch (error) {
    if (error?.response?.data?.statusCode) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.message,
      })
    }
  }
}

export const getListOfEvents = async () => {
  try {
    const currentYear = new Date().getFullYear()
    return await apiClient.get(`${ApiEndPoints.EVENTS}?year=${currentYear}`)
  } catch (error) {
    notification.error({
      message: error?.message,
    })
  }
}

export const getEventTable = async (params) => {
  const { skip, limit, year } = params

  try {
    return await apiClient.get(`${ApiEndPoints.EVENTS}getEventsList`, {
      params: {
        skip,
        limit,
        year,
      },
    })
  } catch (error) {
    if (error) {
      notification.error({
        message: error?.message,
      })
    }
    return null
  }
}

export const getEventsNotification = async () => {
  try {
    return await apiClient.get(`${ApiEndPoints.EVENTS}getEventsNotification`)
  } catch (error) {
    notification.error({
      message: error?.message,
    })
  }
}

export const updateEvent = async (eventData, id) => {
  try {
    return await apiClient.put(ApiEndPoints.EVENTS + id, eventData)
  } catch (error) {
    if (error?.response?.data?.statusCode) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.message,
      })
    }
  }
}
export const deleteEvent = async (id) => {
  try {
    return await apiClient.patch(`${ApiEndPoints.EVENTS}${id}`)
  } catch (error) {
    console.log('error', error)
    if (error?.response?.data?.statusCode) {
      notification.error({
        message: 'Error',
        description: error?.response?.data?.message,
      })
    }
  }
}
